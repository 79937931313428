<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import {findInventoryMonth} from '../../../libs/axios';
export default {
  data () {
    return {
      cdata: "",
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.setData();
     setInterval(this.setData,120000);
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      this.loading = true;
      findInventoryMonth(
      ).then((res) => {
        this.loading = false;
          this.cdata=res.data;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
</style>